import {computeNextPongState, pongH, pongPaddleH, pongPaddleOffset, pongW} from "./pong.js";
import {isolateCursors, socket} from "./cursors.js";

const gameRoot = document.querySelector(".pong-game");

let room, roomSide, joinedPong = false;
let leftPaddle, rightPaddle, ball;

function initPong() {
  if (!ball) {
    leftPaddle = document.createElement("div");
    leftPaddle.className = "paddle";
    leftPaddle.style.height = pongPaddleH + "px";
    leftPaddle.style.left = pongPaddleOffset + "px";
    leftPaddle.style.top = "0px";
    gameRoot.appendChild(leftPaddle);

    rightPaddle = document.createElement("div");
    rightPaddle.className = "paddle";
    rightPaddle.style.height = pongPaddleH + "px";
    rightPaddle.style.right = pongPaddleOffset + "px";
    rightPaddle.style.top = "0px";
    gameRoot.appendChild(rightPaddle);

    ball = document.createElement("div");
    ball.className = "ball";
    ball.style.left = "0px";
    ball.style.top = "0px";
    gameRoot.appendChild(ball);

    gameRoot.addEventListener("mousemove", ev => {
      const pos = Math.min(Math.max(ev.layerY - pongPaddleH / 2, 0), pongH - pongPaddleH);
      if (roomSide === 0) {
        leftPaddle.style.top = `${pos}px`;
      } else {
        rightPaddle.style.top = `${pos}px`;
      }

      socket.emit("set pong", {x: pos});
    });
  }

  socket.off("pong room");
  socket.off("pong join");
  socket.off("pong leave");
  socket.off("pong starting");
  socket.off("pong canceled");
  socket.off("pong started");

  socket.on("pong room", ev => {
    if (!joinedPong) return;

    isolateCursors(ev.players);
    roomSide = ev.players.indexOf(socket.id);
    room = {
      ...ev,
      positions: room?.positions ? [
        roomSide === 0 ? room.positions[0] : ev.positions[0],
        roomSide === 1 ? room.positions[1] : ev.positions[1],
      ] : ev.positions,
    };
    if (!room.ball) room.ball = [pongW / 2, pongH / 2];
    ball.style.left = Math.max(0, Math.min(pongW - 5, room.ball[0] - 2.5)) + "px";
    ball.style.top = Math.max(0, Math.min(pongH - 5, room.ball[1] - 2.5)) + "px";

    if (roomSide === 1) {
      setNames(true, room.names[0] || "", `You --> ${room.names[1] || ""}`);
      leftPaddle.style.top = `${room.positions[0]}px`;
    } else {
      setNames(true, `${room.names[0] || ""} <-- You`, room.names[1] || "");
      rightPaddle.style.top = `${room.positions[1]}px`;
    }

    setScoreText(room.bounces || 0);

    if (room.winner) {
      const weWon = (roomSide + 1) === room.winner;
      if (weWon) {
        setOverlayText("You won! Click to leave");
      } else {
        setOverlayText("You lost! Click to leave");
      }
    }
  });
  socket.on("pong join", () => {
    if (!joinedPong) return;
    setOverlayText("Starting soon");
  });
  socket.on("pong leave", () => {
    if (!joinedPong) return;
    setOverlayText("Looking for players...");
  });
  socket.on("pong starting", ev => {
    if (!joinedPong) return;
    setOverlayText(`Starting in ${ev.countdown}`);
  });
  socket.on("pong canceled", () => {
    if (!joinedPong) return;
    setOverlayText("Looking for players...");
  });
  socket.on("pong started", () => {
    if (!joinedPong) return;
    setOverlayText("");
  });
}

const overlayText = document.querySelector(".pong-text");
function setOverlayText(state) {
  overlayText.textContent = state;
}

const scoreText = document.querySelector(".pong-score");
function setScoreText(state) {
  scoreText.textContent = state;
}

const nameInputControls = document.querySelector(".pong-controls.name-input");
const nameDisplayControls = document.querySelector(".pong-controls.name-display");
const leftName = document.querySelector(".left-name");
const rightName = document.querySelector(".right-name");
const botButton = document.querySelector(".bot-button");

botButton.addEventListener("click", () => {
  socket?.emit("add pong bot");
});

function setNames(show, left, right) {
  if (show) {
    nameInputControls.style.display = "none";
    nameDisplayControls.style.display = "flex";
    botButton.style.display = right ? "none" : "block";
    leftName.textContent = left;
    rightName.textContent = right;
  } else {
    nameInputControls.style.display = "flex";
    nameDisplayControls.style.display = "none";
  }
}

const nameInput = document.querySelector(".pong-name");
function onClickPong() {
  if (!socket) {
    setOverlayText("Enable cursors to play");
    return;
  }
  initPong();

  if (room && !room.started) {
    joinedPong = false;
    isolateCursors(null);
    setNames(false);
    setOverlayText("Click to play Pong");
    setScoreText(0);
    socket.emit("leave pong");
    room = null;
  } else if (!room) {
    joinedPong = true;
    setOverlayText("Looking for players...");
    socket.emit("join pong", {name: nameInput.value});
  }
}

gameRoot.addEventListener("click", onClickPong);

const pingDisplay = document.querySelector(".ping-display");
setInterval(() => {
  if (!socket) {
    pingDisplay.textContent = "?";
  } else {
    const start = Date.now();
    socket.emit("ping", () => {
      pingDisplay.textContent = (Date.now() - start).toString()
    });
  }
}, 1000);
