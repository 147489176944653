export const pongW = 900, pongH = 300, pongPaddleH = 40, pongPaddleOffset = 10;
const pongMaxBounceAngle = 5 * Math.PI / 12;

function getLineIntersection(p0_x, p0_y, p1_x, p1_y, p2_x, p2_y, p3_x, p3_y) {
  let s1_x = p1_x - p0_x;
  let s1_y = p1_y - p0_y;
  let s2_x = p3_x - p2_x;
  let s2_y = p3_y - p2_y;

  let s, t;
  s = (-s1_y * (p0_x - p2_x) + s1_x * (p0_y - p2_y)) / (-s2_x * s1_y + s1_x * s2_y);
  t = ( s2_x * (p0_y - p2_y) - s2_y * (p0_x - p2_x)) / (-s2_x * s1_y + s1_x * s2_y);

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    // Collision detected
    const i_x = p0_x + t * s1_x;
    const i_y = p0_y + t * s1_y;
    return [i_x, i_y];
  }

  return null; // No collision
}

function pythagorasThing(v) {
  return Math.sqrt(v[0] * v[0] + v[1] * v[1]);
}
function pythagorasThingXY(p0_x, p0_y, p1_x, p1_y) {
  return pythagorasThing([p0_x - p1_x, p0_y - p1_y]);
}

export function computeNextPongState(room, deltaT) {
  if (!room.started) return room;

  if (room.ball[0] < 0 || room.ball[0] > pongW) {
    return {
      ...room,
      started: false,
      winner: room.ball[0] < 0 ? 2 : 1,
    };
  }

  const nowBallPos = room.ball;
  let nextBallPos = [
    room.ball[0] + room.ballV[0] * deltaT,
    room.ball[1] + room.ballV[1] * deltaT,
  ];

  const leftPaddle = [
    pongPaddleOffset,
    room.positions[0],
    pongPaddleOffset,
    room.positions[0] + pongPaddleH,
  ];
  const rightPaddle = [
    pongW - pongPaddleOffset,
    room.positions[1],
    pongW - pongPaddleOffset,
    room.positions[1] + pongPaddleH,
  ];

  let headingToPaddle;

  if (room.ballV[0] < 0) {
    headingToPaddle = leftPaddle;
  } else {
    headingToPaddle = rightPaddle;
  }

  let intersection = getLineIntersection(...nowBallPos, ...nextBallPos, ...headingToPaddle);
  if (intersection) {
    const paddleIntersect = (headingToPaddle[1] + (pongPaddleH / 2)) - intersection[1];
    const paddleIntersectNormalized = paddleIntersect / (pongPaddleH / 2);
    const bounceAngle = paddleIntersectNormalized * pongMaxBounceAngle;

    const leftPaddle = room.ballV[0] < 0 ? 1 : -1;

    const ballSpeed = pythagorasThing(room.ballV) + 50;
    const newBallV = [
      ballSpeed * Math.cos(bounceAngle) * leftPaddle,
      ballSpeed * Math.sin(bounceAngle) * -1,
    ];

    const distToIntersection = pythagorasThingXY(...intersection, ...room.ball);
    const remainingDist = ballSpeed * deltaT - distToIntersection;

    const newBall = [
      intersection[0] + remainingDist * Math.cos(bounceAngle) * leftPaddle,
      intersection[1] + remainingDist * Math.sin(bounceAngle) * -1,
    ];

    return {
      ...room,
      ball: newBall,
      ballV: newBallV,
      bounces: room.bounces + 1,
    };
  }

  // check top & bottom oob
  intersection = getLineIntersection(...nowBallPos, ...nextBallPos, ...[0, 0, pongW, 0]);
  if (!intersection) {
    intersection = getLineIntersection(...nowBallPos, ...nextBallPos, ...[0, pongH, pongW, pongH]);
  }
  if (intersection) {
    const ballSpeed = pythagorasThing(room.ballV);
    const distToIntersection = pythagorasThingXY(...intersection, ...room.ball);
    const remainingDist = ballSpeed * deltaT - distToIntersection;

    const normalizedV = room.ballV.map(x => x / ballSpeed);

    const newBall = [
      intersection[0] + remainingDist * normalizedV[0],
      intersection[1] + remainingDist * normalizedV[1] * -1,
    ];

    return {
      ...room,
      ball: newBall,
      ballV: [room.ballV[0], room.ballV[1] * -1],
    }
  }

  return {
    ...room,
    ball: nextBallPos,
  };
}
