import {animate} from "@okikio/animate";
import {startCursors} from "./cursors.js";
import "./pong-client.js";

const redder = "#c21717";
const greener = "#00b314";

function makeBlink(el) {
    const color = el.classList.contains("red") ? redder : greener;

    let previous = `${color} 1px 0 20px, ${color} 1px 0 50px`;
    setInterval(() => {
        const val1 = 20 * (Math.random() + 0.7);
        const val2 = 50 * (Math.random() + 0.7);
        const target = `${color} 0 0 ${val1}px, ${color} 0 0 ${val2}px`;
        animate({
            target: el,
            textShadow: [previous, target],
            duration: 250,
        });
        previous = target;
    }, 300);
}

for (const el of document.getElementsByClassName("blink")) {
    makeBlink(el);
}

setTimeout(() => startCursors(), 500);
