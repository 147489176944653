import {io} from "socket.io-client";
import {animate} from "@okikio/animate";
import {isEqual} from "lodash";
import process from "process";

export let socket;
let offsets;
let eventN = 0;
let containerRoot;
let root;
let cursors;
export let sendMyCursor = true;

let isolatedOverride = null;
export function isolateCursors(list) {
  if (isEqual(list, isolatedOverride)) return;
  isolatedOverride = list;
  if (list) {
    for (const id of Object.keys(cursors)) {
      if (list.includes(id)) continue;
      cursors[id].el.style.display = "none";
    }
  } else {
    for (const cursor of Object.values(cursors)) {
      cursor.el.style.display = "block";
    }
  }
}

function detectCursorType(x, y) {
  const el = document.elementFromPoint(x, y);
  if (!el) return 1;
  const cursor = getComputedStyle(el).cursor;
  switch (cursor) {
    case "pointer":
      return 2;
    case "text":
      return 3;
    case "grab":
      return 4;
    case "grabbing":
      return 5;
    default:
      return 1;
  }
}

const onMouseMove = ev => {
  if (!sendMyCursor) return;
  const msg = {
    x: ev.pageX - offsets.x,
    y: ev.pageY,
    n: eventN++,
    c: detectCursorType(ev.x, ev.y),
  };
  socket.emit("me", msg);
};
const onWindowResize = () => {
  offsets = containerRoot.getBoundingClientRect();
};

function getOffsets() {
  const container = containerRoot.getBoundingClientRect();
  if (container.width < 900) return null;

  return {
    x: container.x,
    y: container.y + window.scrollY,
  };
}

export function startCursors() {
  if (socket) return;
  if (location.host === "sit.sh") {
    socket = io();
  } else {
    socket = io("http://192.168.1.54:4000");
  }
  containerRoot = document.querySelector(".container");
  root = document.querySelector("#cursors");
  cursors = {};

  offsets = getOffsets();
  if (!offsets) return;

  socket.on("u", msg => {
    let cursor = cursors[msg.id];
    if (!cursor) {
      const el = document.createElement("div");
      el.className = "cursor";
      root.appendChild(el);
      cursor = {el, x: msg.x, y: msg.y, n: msg.n, c: msg.c};
      cursors[msg.id] = cursor;
    }
    if (cursor.n > msg.n) return;

    animate({
      target: cursor.el,
      top: [cursor.y - 16, msg.y - 16],
      left: [cursor.x + offsets.x - 16, msg.x + offsets.x - 16],
      duration: 16, // for 60 fps?
      easing: "linear",
    });

    cursor.el.style.top = `${msg.y - 16}px`;
    cursor.el.style.left = `${msg.x + offsets.x - 16}px`;
    if (isolatedOverride && !isolatedOverride.includes(msg.id)) {
      cursor.el.style.display = "none";
    } else {
      cursor.el.style.display = "block";
    }
    cursor.el.className = `cursor t${msg.c}`;

    cursors[msg.id].x = msg.x;
    cursors[msg.id].y = msg.y;
  });
  socket.on("remove", msg => {
    let cursor = cursors[msg.id];
    if (!cursor) return;
    root.removeChild(cursor.el);
    delete cursors[msg.id];
  });

  window.addEventListener("mousemove", onMouseMove);
  window.addEventListener("resize", onWindowResize);
}

function stopCursors() {
  if (!socket) return;
  window.removeEventListener("mousemove", onMouseMove);
  window.removeEventListener("resize", onWindowResize);
  socket.disconnect();
  for (const cursor of Object.values(cursors)) {
    cursor.el.remove();
  }
  socket = null;
}

function hookCheckbox(id, cb) {
  const checkbox = document.getElementById(id);
  checkbox.addEventListener("change", ev => {
    cb(ev.target.checked);
  });
}
hookCheckbox("hide-my-cursor", hide => {
  sendMyCursor = !hide;
  socket?.emit("rm");
});
hookCheckbox("hide-cursors", hide => {
  if (hide) stopCursors();
  else startCursors();
});
